<template>
  <div id="lay-header">
    <div class="logo">
      <img class="logo-img" src="../../assets/images/mine/logo-s.png" alt="" />
      <span>商户管理系统</span>
    </div>
    <div class="title">
      <div class="title-L">
        <!-- <span class="active">我的任务</span> -->
      </div>
      <div class="title-R">
        <el-dropdown>
          <div class="active myinfo">
            <el-avatar
              size="small"
              :src="
                (userInfo.userAvatarUrl && userInfo.userAvatarUrl.filePath) ||
                userAvatar
              "
            ></el-avatar>
            <span style="color: #c0c4cc">丨</span>
            <span>{{ userInfo.userName }}</span>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="link">个人资料</el-dropdown-item>
            <el-dropdown-item @click.native="goOpenPassword"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 修改密码弹框 -->
    <el-dialog
      :visible.sync="editShow"
      :title="editTitle"
      :close-on-click-modal="false"
      width="20%"
      custom-class="add-dialog"
      @closed="closeDialog()"
    >
      <el-form :model="params" v-if="!isCheck">
        <el-form-item>
          <el-input disabled v-model="params.mobile"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请填写验证码"
            v-model="params.imageCode"
            maxlength="4"
          >
            <template #append>
              <img
                style="width: 80px; height: 30px; cursor: pointer"
                :src="imageBase64Data"
                @click="getImageCode"
              />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="params.securitycode"
            placeholder="请输入短信验证码"
          >
            <template #append>
              <div
                v-if="!isCountDown"
                class="securityCode"
                @click="setSecuritycode()"
              >
                发送验证码
              </div>
              <div class="securityCode" v-else>{{ time }}s</div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-form :model="params" v-else>
        <el-form-item>
          <el-input
            placeholder="请输入新密码"
            v-model="params.password"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请确认新密码"
            v-model="params.repassword"
            show-password
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="goCheckCode()" v-if="!isCheck"
          >验证</el-button
        >
        <el-button type="primary" @click="goChangePassword()" v-else
          >更改密码</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMyselfInfo, getLogout } from "@/api/setting/user";
import {
  goSendMobileCode,
  goCheckMobileCode,
  goChangePassword,
  getImageCode,
} from "@/api/common.js";
export default {
  data() {
    return {
      userAvatar:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      userInfo: {},

      editShow: false,
      editTitle: "",
      saveBtnTitle: "",
      params: {
        mobile: "",
        codeId: null, //验证码id
        imageCode: "", //图形验证码
        securitycode: "", // 手机验证码
        password: "", // 密码
        repassword: "",
      },
      imageBase64Data: "", //验证码图片
      isCountDown: false, // 是否展示倒计时
      time: 60, // 倒计时时间
      isCheck: false,
    };
  },
  mounted() {
    this.getMyselfInfo();
  },
  methods: {
    // 弹框关闭
    closeDialog() {
      this.editTitle = "验证身份";
      this.saveBtnTitle = "验证";
      this.params.imageCode = "";
      this.params.securitycode = "";
      this.isCheck = false;
    },
    // 发送验证码
    setSecuritycode() {
      if (this.params.imageCode == "") {
        this.$message.error({
          message: "请输入图形验证码",
        });
      } else {
        this.goSendMobileCode();
      }
    },
    // 验证手机验证码
    goCheckCode() {
      if (this.params.securitycode == "") {
        this.$message.error({
          message: "请输入手机验证码",
        });
      } else {
        this.goCheckMobileCode();
      }
    },
    // 【请求】发送手机验证码
    goSendMobileCode() {
      let params = {
        phoneNumber: this.params.mobile,
        codeId: this.params.codeId,
        imageCode: this.params.imageCode,
      };
      goSendMobileCode(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.isCountDown = true;
          this.$message({
            message: "验证码发送成功",
            type: "success",
          });
          // 倒计时
          setInterval(() => {
            this.time = this.time - 1;
            if (this.time == 0) {
              clearInterval();
              this.time = 60;
              this.isCountDown = false;
            }
          }, 1000);
        }
      });
    },
    // 【请求】验证手机验证码
    goCheckMobileCode() {
      let params = {
        code: this.params.securitycode,
        phoneNumber: this.params.mobile,
      };
      goCheckMobileCode(params).then(() => {
        this.params.password = "";
        this.params.repassword = "";
        this.editTitle = "更改密码";
        this.isCheck = true;
      });
    },
    // 【请求】获取图片验证码
    getImageCode() {
      getImageCode().then((res) => {
        if (res.isSuccess == "yes") {
          this.params.codeId = res.data.codeId;
          this.imageBase64Data = res.data.imageBase64Data;
        }
      });
    },
    // 【请求】修改密码
    goChangePassword() {
      if (this.params.password == "" || this.params.password.length < 6) {
        this.$message.error({
          message: "请输入不少于6位的新密码",
        });
      } else if (
        this.params.repassword == "" ||
        this.params.password != this.params.repassword
      ) {
        this.$message.error({
          message: "两次密码不一致",
        });
      } else {
        let params = {
          userMobile: this.params.mobile,
          password: this.params.password,
          code: this.params.securitycode,
        };
        goChangePassword(params).then(() => {
          this.$message({
            message: "密码修改成功",
            type: "success",
          });
          this.editShow = false;
        });
      }
    },
    // 修改密码
    goOpenPassword() {
      this.getImageCode();
      this.editTitle = "验证身份";
      this.saveBtnTitle = "验证";
      this.params.imageCode = "";
      this.params.securitycode = "";
      this.isCheck = false;
      this.editShow = true;
    },
    // 【请求】个人信息
    getMyselfInfo() {
      getMyselfInfo().then((res) => {
        this.userInfo = res.data;
        console.log(this.userInfo);
        window.localStorage.setItem('spId', res.data.spId);//存入token
        this.params.mobile = res.data.mobile;

      });
    },

    // 跳转我的资料
    link() {
      if (this.$route.path != "/setting/myselfInfo") {
        this.$router.push({
          path: "/setting/myselfInfo",
        });
      }
    },

    // 退出登录
    logout() {
      this.$router.push({
        path: "/mine/login",
      });
      getLogout({}).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          window.localStorage.removeItem("playOne-merchant-token");
          window.localStorage.removeItem("playOne-merchant-permissionArr");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#lay-header {
  height: 60px;
  display: flex;


}

.logo {
  width: 200px;
  background: #3b466a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.16rem;
}

.logo-img {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 0.1rem;
}

.title {
  flex: 1;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 1px 2px 4px rgba(0, 21, 41, 0.16);
  z-index: 1;
  height: 100%;
}

.title-R,
.title-L {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-R > .el-dropdown {
  height: 100%;
}

.active {
  height: 100%;
  display: flex;
  padding: 0 24px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.active:hover {
  background: rgba(0, 0, 0, 0.1);
}

.myinfo {
  font-size: 0.16rem;
}
.securityCode {
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
</style>
<style lang="scss">
#lay-header  {
  .el-input-group__append {
    padding: 0 0.1rem;
    height: 100%;
  }
}
</style>