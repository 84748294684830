<template>
  <div id="layout">
    <lay-header></lay-header>
    <main>
      <lay-menu :menulist="menulist"></lay-menu>
      <div class="views">
        <!-- <transition enter-active-class="animate__animated animate__fadeInRight">
          <router-view :key="$route.fullPath">路由展示区</router-view>
        </transition> -->
        <router-view :key="$route.fullPath">路由展示区</router-view>
      </div>
    </main>
  </div>
</template>


<script>
import { getMenu } from "@/api/setting/menu";
import layMenu from "./lay-menu.vue";
import layHeader from "./lay-header.vue";
export default {
  components: { layMenu, layHeader },
  data() {
    return {
      menulist: []
    };
  },
  created() {
    this.getMenu();
  },
  methods: {
    // 【緩存】菜单权限
    getPermissionArr(menu) {
      let arr = [];
      let permissionArr = [];
      if (menu && menu.length > 0) {
        for (let i of menu) {
          if (i.functionVos) {
            for (let j of i.functionVos) {
              if (j.functionVos) {
                arr = [...arr, ...j.functionVos]
              }
            }
          }
        }
      }
      if (arr && arr.length > 0) {
        for (let i of arr) {
          permissionArr = [...permissionArr, i.funCode]
        }
      }
      window.localStorage.setItem('playOne-merchant-permissionArr', JSON.stringify(permissionArr));
    },

    // 【请求】菜单
    getMenu() {
      let data = {
        menuType: 0
      }
      getMenu(data).then(res => {
        if (res.isSuccess == "yes") {
          this.menulist = res.data;
          if (this.menulist && this.menulist.length > 0) {
            this.getPermissionArr(this.menulist); // 【緩存】菜单权限
          } else {
            this.$message({
              message: "未配置菜单权限，请先配置菜单权限",
              type: "warning",
            });
            this.$router.push('/mine/login');
          }
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";

#layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  height: calc(100% - 60px);
  display: flex;
}

.views {
  width: calc(100% - 200px);
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  border: 15px solid $bg-color-padding;
}
</style>